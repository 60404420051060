<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>弹窗设置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="弹窗名称">
                <el-input v-model="search.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="城市">
                <el-select v-model="search.region_code" placeholder="请选择城市" style="width: 100%">
                  <el-option v-for="(item, index) in cityList" :key="index" :label="item.region_name"
                    :value="item.region_code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="弹窗状态">
                <el-select v-model="search.state" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="禁用" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="添加时间">
                <el-date-picker style="width: 70%" v-model="searchtime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">
              显示搜索
            </el-button>
            <el-button v-if="is_auth('weapphome.banner.isadd')" icon="el-icon-plus" size="medium" type="primary"
              @click="add_page">添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 40px"></div>
      <!--列表-->
      <el-table v-loading="loading" :data="tableData" border size="medium">
        <el-table-column prop="id" label="ID" width="40"> </el-table-column>
        <el-table-column prop="type" label="弹窗位置" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.positions === 'home'">首页</span>
            <span v-if="scope.row.positions === 'square'">广场</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="弹窗名称" width="160"></el-table-column>
        <el-table-column prop="img" label="弹窗图" width="130">
          <template slot-scope="scope">
            <el-image style="height: 22px; margin-right: 4px" :src="scope.row.img[0]" fit="contain"
              :preview-src-list="scope.row.img">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="city" label="城市" width="160"></el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === -1" type="danger" size="small">删除</el-tag>
            <el-tag v-if="scope.row.state === 1" size="small">正常</el-tag>
            <el-tag v-if="scope.row.state === 2" type="warning" size="small">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="mechanism" label="弹窗机制" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.mechanism === 0">待设置</div>
            <div v-if="scope.row.mechanism === 1">
              每日{{ scope.row.mechanism_num }}次
            </div>
            <div v-if="scope.row.mechanism === 2">
              每{{ scope.row.mechanism_num }}小时1次
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="jump_type" label="跳转类型" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.jump_type === 0" type="info" size="mini">不跳转</el-tag>
            <el-tag v-if="scope.row.jump_type === 1" effect="dark" type="success" size="mini">H5网址</el-tag>
            <el-tag v-if="scope.row.jump_type === 2" effect="dark" size="mini">小程序</el-tag>
            <el-tag v-if="scope.row.jump_type === 3" effect="dark" size="mini">底部tab页面</el-tag>
            <el-tag v-if="scope.row.jump_type === 4" effect="dark" size="mini">图片</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="jump_url" label="跳转地址" width="300" show-overflow-tooltip />
        <el-table-column prop="start_at" label="展示时间" width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.start_at }}至{{ scope.row.end_at }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state === 1" @click="operation_tip(scope.row)" type="danger" size="mini">禁用
            </el-button>
            <el-button v-if="scope.row.state === 2" @click="operation_tip(scope.row)" type="primary" size="mini">启用
            </el-button>
            <el-button v-if="scope.row.state === 1" @click="editBanner(scope.row)" type="primary" size="mini">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>

      <!--编辑-->
      <el-dialog v-loading="loading" v-if="is_auth('weapphome.banner.isadd')" title="添加弹窗" :visible.sync="editPage"
        width="50%" :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-form size="medium" :label-width="this.env.label_width" :model="info" :rules="dataRule" ref="dataForm"
            @submit.native.prevent @keyup.enter.native="save()">
            <el-col :span="24">
              <el-form-item label="弹窗名称" key="name" prop="name">
                <el-input v-model="info.name" maxlength="10"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上传弹窗图片" key="img" prop="img">
                <Qnupload v-model="info.img" :sum="1" :compress="false" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="城市" key="region_code" prop="region_code">
                <el-select v-model="info.region_code" placeholder="请选择城市" widht="100%">
                  <el-option v-for="(item, index) in cityList" :key="index" :label="item.region_name"
                    :value="item.region_code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="弹窗位置" key="positions" prop="positions">
                <el-select v-model="info.positions" placeholder="请选择页面" widht="100%">
                  <el-option label="首页" value="home"></el-option>
                  <el-option label="广场" value="square"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="跳转类型" key="jump_type" prop="jump_type">
                <el-radio v-model="info.jump_type" :label="0">不跳转</el-radio>
                <el-radio v-model="info.jump_type" :label="1">H5网址</el-radio>
                <el-radio v-model="info.jump_type" :label="2">小程序路径</el-radio>
                <el-radio v-model="info.jump_type" :label="3">底部tab页面</el-radio>
                <el-radio v-model="info.jump_type" :label="4">图片</el-radio>
                <el-radio v-model="info.jump_type" :label="5">外部小程序</el-radio>
              </el-form-item>
            </el-col>
            <el-col v-if="info.jump_type !== 0" :span="24">
              <template v-if="info.jump_type === 5">
                <el-form-item label="小程序信息" prop="jump_url" key="jump_url">
                  <el-input v-model="info.jump_url" maxlength="500"></el-input>
                  <span style="font-size:12px;color:#fc142f">格式：appid,小程序路径参数，如
                    wxe09c719dec79ec9f,/pages/home/index/index?type=1</span>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item label="跳转地址" v-if="info.jump_type !== 4" key="jump_url" prop="jump_url">
                  <el-input v-model="info.jump_url" maxlength="255"></el-input>
                </el-form-item>
                <el-form-item label="上传图片" v-else prop="jump_url">
                  <Qnupload v-model="info.jump_url" :sum="1" :compress="false" />
                </el-form-item>
              </template>
            </el-col>
            <el-col :span="24">
              <el-form-item label="弹窗机制" prop="mechanism">
                <el-radio v-model="info.mechanism" :label="1">
                  每日最多
                  <el-input-number v-model="info.num" controls-position="right" width="80" size="small" :min="1"
                    :max="10"></el-input-number>次
                </el-radio>
                <el-radio v-model="info.mechanism" :label="2">
                  每<el-input-number v-model="info.num1" controls-position="right" width="80" size="small" :min="1"
                    :max="10"></el-input-number>小时1次
                </el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="展示时间" prop="exhibition_time">
                <el-date-picker v-model="info.exhibition_time" type="datetimerange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input v-model="info.remark" maxlength="10"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button size="medium" type="primary" @click="save">添 加</el-button>
          <el-button size="medium" @click="editPage = false">取 消</el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Qnupload from "@/components/Qnupload";

export default {
  components: {
    Qnupload,
  },
  data() {
    return {
      page_name: "弹窗列表",
      issearch: false,
      loading: true, // 加载状态
      count: 0, // 数据总条数
      tableData: [], // 列表内容
      search: {}, // 搜索内容
      page: 1, // 当前页数
      info: {}, // 编辑数据
      searchtime: null, // 时间条件
      editPage: false,
      needLogin: false, // 设置的banner是否需要新用户登录
      keyword_one: "",
      keyword_two: "",
      cityList: [],
      dataRule: {
        name: [
          { required: true, message: "弹窗名称不能为空", trigger: "blur" },
        ],
        img: [{ required: true, message: "请上传弹窗图片", trigger: "blur" }],
        region_code: [
          { required: true, message: "请选择城市", trigger: "blur" },
        ],
        jump_type: [
          { required: true, message: "请选择跳转类型", trigger: "change" },
        ],
        jump_url: [
          { required: true, message: "请填写跳转地址", trigger: "change" },
        ],
        positions: [
          { required: true, message: "请选择弹窗位置", trigger: "change" },
        ],
        mechanism: [
          { required: true, message: "请选择弹窗机制", trigger: "change" },
        ],
        exhibition_time: [
          { required: true, message: "请选择展示时间", trigger: "change" },
        ],
      },
    };
  },
  // 创建
  created() {
    this.init();
    this.getRegion();
  },
  // 安装
  mounted() { },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        name: "",
        city: "",
        state: "",
        create_start: "",
        create_end: "",
      };
      this.searchtime = null;
      this.is_search();
    },
    // 获取城市列表
    getRegion() {
      let postdata = {
        api_name: "systemset.cityset.getlist",
        token: this.Tool.get_l_cache("token"),
      };
      Object.assign(postdata);

      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        console.log(res);
        if (res.code === 0) {
          this.cityList = res.data;
        }
      });
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "popup.notification.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.create_start = this.searchtime[0];
        postdata.create_end = this.searchtime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.$message.error(json.message);
        }
      });
    },
    // 操作提示
    operation_tip(item) {
      let tip =
        item.state === 1
          ? `禁用【${item.name}】弹窗设置？`
          : `启用【${item.name}】弹窗设置？`;

      // 弹出二次确认
      this.$confirm(tip, "确认信息", {})
        .then(() => {
          this.isoperation(item);
        })
        .catch(() => { });
    },
    // 操作
    isoperation(item) {
      let postdata = {
        api_name: "popup.notification.setstate",
        token: this.Tool.get_l_cache("token"),
        id: item.id,
        state: item.state === 1 ? 2 : 1,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.$message.error(json.message);
        }
      });
    },
    // 添加页面显示
    add_page() {
      this.tag_uuid = "";
      this.info = {
        orderby: "",
        ad_name: "",
        imgurl: "",
        to_url_type: 0,
        to_url: "",
        exhibition_time: [],
        needLogin: false,
        type: "banner",
      };
      this.editPage = true; // 显示页面
    },
    // 保存
    save() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let postdata = {
            api_name: "popup.notification.saves",
            token: this.Tool.get_l_cache("token"),
          };
          console.log(this.info, 542);
          if (this.info.exhibition_time.lenght != 0) {
            this.info.start_at = this.info.exhibition_time[0];
            this.info.end_at = this.info.exhibition_time[1];
          }
          if (this.info.mechanism === 1) {
            this.info.mechanism_num = this.info.num;
          } else {
            this.info.mechanism_num = this.info.num1;
          }
          Object.assign(postdata, this.info);
          postdata.img = [this.info.img];
          this.loading = true;
          this.Tool.post_data("oss", postdata, (json) => {
            this.loading = false;
            if (json.code === 0) {
              this.editPage = false;
              this.$message({
                message: "保存成功",
                type: "success",
                duration: this.env.message_duration,
                onClose: () => {
                  this.getlist();
                },
              });
            } else {
              this.$message.error(json.message);
            }
          });
        }
      });
    },
    // 编辑
    editBanner(item) {
      this.editPage = true;
      let region_code = this.cityList.filter(
        (i) => i.region_name === item.city
      );
      console.log(region_code, 582);
      this.info = {
        exhibition_time: [item.create_at, item.end_at],
        ...item,
        num: item.mechanism === 1 ? item.mechanism_num : "",
        num1: item.mechanism === 2 ? item.mechanism_num : "",
        img: item.img != [] ? item.img[0] : "",
        region_code:
          region_code && region_code[0] ? region_code[0].region_code : "",
      };
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

>>>.el-carousel__container {
  height: 140px;
}

>>>.el-carousel__container img {
  width: 100%;
}

>>>.el-input-number--small {
  width: 80px;
  margin: 0 5px;
}

>>>.el-input-number.is-controls-right .el-input__inner {
  padding-left: 10px;
}
</style>
  